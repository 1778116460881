<template>
  <div class="questionnaire__page">
    <form-element
      v-for="(item, index) in sortedElements"
      v-model="sortedElements[index]"
      :key="`form_element_${item.id}`"
      :readOnly="readOnly"
      :printView="printView"
      :preview="preview"
      :feedbackId="feedbackId"
      @errorOnSave="$emit('errorOnSave')"
    ></form-element>
  </div>
</template>

<script>
import formElement from '@/components/forms/questionnaire/element.vue';

export default {
  props: {
    page: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    },

    preview: {
      type: Boolean,
      required: false,
      default: false
    },

    feedbackId: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {};
  },

  computed: {
    sortedElements() {
      const items = [...this.page.elements];
      return items.sort((a, b) => {
        if (a.index < b.index) {
          return -1;
        } else if (a.index > b.index) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  },

  components: {
    formElement
  }
};
</script>
