<template>
  <div class="px-2 px-sm-0" v-html="localize(value.title)"></div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  }
};
</script>

<style></style>
