<template>
  <v-simple-table class="question-matrix-table">
    <thead>
      <tr>
        <th class="question-col">{{ $tc('forms.question', 0) }}</th>
        <th
          v-for="comparisonItem in feedbackData.comparisonData"
          :key="`header_fb_${comparisonItem.feedbackId}`"
        >
          {{ $t(`feedbacks.typeOptions.${comparisonItem.feedbackType}`) }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in sortedItems" :key="`matrix_item_${item.id}`">
        <td>
          <template v-if="localize(item.subtitle) !== '' && !printView">
            {{ localize(item.title) }}
            <v-tooltip bottom max-width="500" content-class="tooltip-wrapper">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-information</v-icon>
              </template>
              <div>{{ localize(item.subtitle) }}</div>
            </v-tooltip>
          </template>
          <template v-else>
            {{ localize(item.title) }}
          </template>
        </td>
        <td
          v-for="comparisonItem in feedbackData.comparisonData"
          :key="`fb_${comparisonItem.feedbackId}_item_${item.id}`"
        >
          <div class="scale-wrapper">
            <div>
              <item-scale
                :value="getItem(item.id, comparisonItem.feedbackType)"
                :availableValues="availableValues"
                :anchors="value.anchors"
                :readOnly="true"
                :printView="false"
              ></item-scale>
            </div>

            <div class="item-comment pt-4">
              {{ getComment(item.id, comparisonItem.feedbackType) }}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import elementMixin from '@/mixins/form-element.js';
import itemScale from '@/components/forms/questionnaire/form-elements/item-scale.vue';

import { mapState } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  mixins: [elementMixin],

  data() {
    return {
      availableValues: []
    };
  },

  computed: {
    ...mapState('feedbacks', {
      feedbackData: 'currentFeedbackData'
    }),

    sortedItems() {
      const itemsCopy = [...this.value.items];
      return itemsCopy.sort((a, b) => {
        if (a.index < b.index) {
          return -1;
        } else if (a.index > b.index) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  },

  mounted() {
    const values = [];
    for (let i = this.value.minValue; i <= this.value.maxValue; i++) {
      values.push(i);
    }

    this.availableValues = values;
  },

  methods: {
    getComment(itemId, feedbackType) {
      var item = this.feedbackData.comparisonData.filter(
        (x) => x.feedbackType === feedbackType
      );
      if (item.length === 0) {
        return '';
      }

      var val = item[0].comments.filter(
        (x) => x.elementId === this.value.id && x.matrixItemId === itemId
      );

      if (val.length === 0) {
        return '';
      }

      return val[0].comment;
    },

    getItem(itemId, feedbackType) {
      var item = this.feedbackData.comparisonData.filter(
        (x) => x.feedbackType === feedbackType
      );
      if (item.length === 0) {
        return -1;
      }

      var val = item[0].values.filter(
        (x) => x.elementId === this.value.id && x.matrixItemId === itemId
      );

      if (val.length === 0) {
        return -1;
      }

      return val[0].value;
    }
  },

  components: {
    itemScale
  }
};
</script>

<style lang="scss" scoped>
td {
  vertical-align: top;
}

.question-matrix-table::v-deep {
  table {
    table-layout: fixed;
  }
}

.question-col {
  width: 25%;
}

.scale-col {
  width: auto;
}

tbody tr td {
  background-color: var(--v-background-base);
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

tbody tr:first-of-type td {
  padding-top: 16px !important;
}

.tooltip-wrapper {
  white-space: pre-wrap;
}

.theme--light.v-data-table.question-matrix-table {
  margin-top: 1em;

  thead > tr > th {
    background-color: var(--v-secondary-base);
    color: var(--v-primary-base-font);
  }
}

.item-comment {
  display: block;
  clear: both;
  white-space: pre-line;
  word-wrap: break-word;
}
</style>
