import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('forms', {
      customSettings: 'customSettings'
    })
  },

  methods: {
    getIconClass(val, selectedValue) {
      var iconSettings = this.customSettings?.formSettings?.scaleIcons;

      const valItems = iconSettings.filter((x) => x.value === val);
      if (valItems.length > 0) {
        return selectedValue === val
          ? valItems[0].iconChecked
          : valItems[0].iconUnchecked;
      }

      return selectedValue === val
        ? 'mdi-checkbox-intermediate'
        : 'mdi-checkbox-blank-outline';
    },

    getIconColor(val, readOnly) {
      var iconSettings = this.customSettings?.formSettings?.scaleIcons;

      const valItems = iconSettings.filter((x) => x.value === val);
      if (valItems.length > 0) {
        if (readOnly) {
          return this.addTransparancy(valItems[0].color, 0.5);
        } else {
          return valItems[0].color;
        }
      }

      return '#999999';
    },

    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
        : null;
    },

    addTransparancy(hexColor, transparancyValue) {
      var rgb = this.hexToRgb(hexColor);
      return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, ${transparancyValue})`;
    }
  }
};
