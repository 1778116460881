<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        :color="$attrs.color"
        :loading="$attrs.loading"
        :disabled="$attrs.disabled"
      >
        <slot name="button">
          <v-icon>mdi-delete-forever</v-icon>
        </slot>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5"><slot name="header"></slot></span>
      </v-card-title>
      <v-card-text>
        <slot></slot>

        <v-form ref="form" v-model="isValid" lazy-validation>
          <v-textarea
            v-model="comment"
            filled
            auto-grow
            cols="5"
            class="mt-4"
            required
            :rules="[(v) => !!v || $t('validation.fieldIsRequired')]"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancel">
          <slot name="close-button">{{ $t('cancel') }}</slot>
        </v-btn>
        <v-btn color="blue darken-1" text @click="confirm">
          <slot name="confirm-button">{{ $t('save') }}</slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    comment: '',
    isValid: false
  }),

  methods: {
    cancel() {
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.dialog = false;
    },

    async confirm() {
      await this.$refs.form.validate();
      if (this.isValid) {
        this.$emit('confirmed', this.comment);
        this.dialog = false;

        this.$refs.form.resetValidation();
        this.$refs.form.reset();
      }
    }
  }
};
</script>
