<template>
  <datepicker
    v-model="date"
    @change="$emit('change')"
    :first-day-of-week="1"
    :readOnly="readOnly || printView"
    filled
  ></datepicker>
</template>

<script>
import datepicker from '@/components/misc/datepicker.vue';
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    date: ''
  }),

  watch: {
    date() {
      this.$emit('input', this.date);
    },

    value() {
      this.date = this.value;
    }
  },

  components: {
    datepicker
  }
};
</script>
