<template>
  <v-row class="flex-row">
    <v-col cols="12">
      <template v-if="loading">
        <v-skeleton-loader
          type="table-heading, list-item-two-line, image, table-tfoot"
        ></v-skeleton-loader>
      </template>

      <template v-else-if="userHasAccess">
        <form-page
          :page="currentPage"
          :readOnly="readOnly"
          :printView="printView"
          :preview="preview"
          :feedbackId="feedbackId"
          @errorOnSave="$emit('errorOnSave')"
        ></form-page>

        <comment-form
          v-if="feedbackData !== null && (showCommentForm || showCommentText)"
          v-model="feedbackData.feedbackComment"
          :readOnly="!showCommentForm"
          class="mb-8"
          @save="onFeedbackCommentSaved"
        ></comment-form>

        <!--
					TODO: the v-if directive hardwires the alter only to hays mafeg processes.
					However we need to make sure that this doesn't cause any issues for other customers.
					We should try to find another way to enable/disable the info message either via
					the workflow settings, customer specific translations with checks if they are available
					or some other way.
				-->
        <v-alert prominent text type="info" class="mb-4" v-if="showUpdateInfo">
          {{
            $t(
              `feedbacks.updateInfo.${feedbackData.processType}.${feedbackData.feedbackType}`
            )
          }}
        </v-alert>

        <div class="text-center">
          <template v-if="showSubmitButton">
            <v-btn
              v-if="showFakeSaveButton"
              color="primary"
              :loading="saving"
              :disabled="!feedbackData.canUpdate && submitButtonDisabled"
              @click="saveForm"
              class="mr-2"
            >
              <v-icon class="mr-2">mdi-content-save</v-icon>
              {{ $t('save') }}
            </v-btn>

            <v-btn
              color="primary"
              :loading="submitting"
              :disabled="submitButtonDisabled"
              @click="submitForm"
              class="mr-2"
            >
              <template v-if="submitButtonDisabled">
                <v-icon>mdi-check</v-icon>
                {{ $t('feedbacks.feedbackSubmitted') }}
              </template>

              <template v-else>
                <v-icon class="mr-2">{{ submitButtonIcon }}</v-icon>
                {{ submitButtonLabel }}
              </template>
            </v-btn>
          </template>

          <template v-if="showApproveButton">
            <v-btn
              color="primary"
              :loading="approving"
              :disabled="approvalComplete || vetoComplete"
              @click="approveForm"
              class="mr-2"
            >
              <template v-if="approvalComplete">
                <v-icon>mdi-check</v-icon>
                {{ $t('feedbacks.feedbackApproved') }}
              </template>

              <template v-else>{{ $t('feedbacks.approve') }}</template>
            </v-btn>
          </template>

          <template v-if="showVetoButton">
            <comment-dialog
              color="error"
              :loading="vetoing"
              :disabled="vetoComplete || approvalComplete"
              @confirmed="vetoForm"
            >
              <template v-slot:button>
                <template v-if="vetoComplete">
                  <v-icon>mdi-check</v-icon>
                  {{ $t('feedbacks.vetoSaved') }}
                </template>

                <template v-else>
                  <v-icon>mdi-cancel</v-icon>
                  {{ $t('feedbacks.veto') }}
                </template>
              </template>

              {{ $t('feedbacks.vetoCommentInfo') }}
            </comment-dialog>
          </template>
        </div>

        <div v-if="numPages > 1" class="text-center mt-12">
          <v-pagination v-model="pageIndex" :length="numPages"></v-pagination>
        </div>
      </template>

      <template v-else>
        <v-alert type="warning" icon="mdi-cancel">
          {{ $t('feedbacks.noAccessError') }}
        </v-alert>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { v4 as uuid } from 'uuid';
import { mapState } from 'vuex';
import formPage from '@/components/forms/questionnaire/page.vue';
import commentForm from './feedback-comment-form.vue';
import commentDialog from '@/components/dialogs/comment.vue';

export default {
  components: {
    formPage,
    commentForm,
    commentDialog
  },

  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    },

    preview: {
      type: Boolean,
      required: false,
      default: false
    },

    form: {
      type: Object,
      required: false,
      default: null
    },

    loading: {
      type: Boolean,
      required: true
    },

    feedbackId: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: () => ({
    pageIndex: 1,
    saving: false,
    submitting: false,
    submitComplete: false,
    approving: false,
    approvalComplete: false,
    vetoing: false,
    vetoComplete: false,
    correlationId: null
  }),

  computed: {
    ...mapState('feedbacks', {
      feedbackData: 'currentFeedbackData'
    }),

    currentPage() {
      return this.formContent.pages.filter(
        (x) => x.pageIndex === this.pageIndex
      )[0];
    },

    formContent() {
      if (this.form !== null && this.form !== undefined) {
        return this.form;
      } else if (this.feedbackData !== null) {
        return this.feedbackData.formContent;
      } else {
        return {};
      }
    },

    numPages() {
      return this.formContent.pages.length;
    },

    showSubmitButton() {
      if (this.readOnly || this.printView) {
        return false;
      }

      if (this.numPages > 1 && this.pageIndex != this.numPages) {
        return false;
      }

      return true;
    },

    showApproveButton() {
      if (this.printView || this.preview) {
        return false;
      }

      if (this.feedbackData === null) {
        return false;
      }

      if (this.numPages > 1 && this.pageIndex != this.numPages) {
        return false;
      }

      return this.feedbackData.canApprove;
    },

    showCommentForm() {
      if (this.printView || this.preview) {
        return false;
      }

      if (this.numPages > 1 && this.pageIndex != this.numPages) {
        return false;
      }

      return this.feedbackData.canComment;
    },

    showCommentText() {
      if (this.printView || this.preview) {
        return false;
      }

      return (
        this.feedbackData.feedbackComment !== undefined &&
        this.feedbackData.feedbackComment !== null &&
        this.feedbackData.feedbackComment.trim() !== ''
      );
    },

    showFakeSaveButton() {
      if (this.printView || this.preview) {
        return false;
      }

      if (this.feedbackData === null) {
        return false;
      }

      if (!this.feedbackData.canUpdate) {
        return true;
      }

      if (
        this.feedbackData.canUpdate &&
        this.feedbackData.canWrite &&
        !this.feedbackData.isSubmitted
      ) {
        return true;
      }

      return false;
    },

    showUpdateInfo() {
      if (this.printView || this.preview) {
        return false;
      }

      if (!this.feedbackData.canUpdate && !this.feedbackData.canWrite) {
        return false;
      }

      if (this.feedbackData.isInFinalStatus) {
        return false;
      }

      return (
        this.$t(
          `feedbacks.updateInfo.${this.feedbackData.processType}.${this.feedbackData.feedbackType}`
        ) !== ''
      );
    },

    showVetoButton() {
      if (this.printView || this.preview) {
        return false;
      }

      if (this.numPages > 1 && this.pageIndex != this.numPages) {
        return false;
      }

      return this.feedbackData.canVeto;
    },

    submitButtonDisabled() {
      if (this.printView || this.preview) {
        return true;
      }

      if (this.feedbackData.canUpdate && !this.showFakeSaveButton) {
        return false;
      }

      return this.submitComplete;
    },

    submitButtonIcon() {
      if (this.printView || this.preview) {
        return '';
      }

      if (this.showFakeSaveButton) {
        return 'mdi-send';
      }

      if (
        this.feedbackData.canUpdate ||
        (this.feedbackData.canWrite && !this.feedbackData.isSubmitted)
      ) {
        return 'mdi-content-save';
      } else {
        return 'mdi-send';
      }
    },

    submitButtonLabel() {
      if (this.printView || this.preview) {
        return '';
      }

      if (this.showFakeSaveButton) {
        return this.$t('feedbacks.submit');
      }

      if (
        this.feedbackData.canUpdate ||
        (this.feedbackData.canWrite && !this.feedbackData.isSubmitted)
      ) {
        return this.$t('save');
      } else {
        return this.$t('feedbacks.submit');
      }
    },

    userHasAccess() {
      if (!this.feedbackData && this.preview) {
        return true;
      }

      return (
        this.feedbackData.canRead ||
        this.feedbackData.canWrite ||
        this.feedbackData.canUpdate ||
        this.feedbackData.canApprove ||
        this.feedbackData.canVeto ||
        this.feedbackData.canComment
      );
    }
  },

  beforeDestroy() {
    if (this.correlationId !== null) {
      this.$feedbackSubmittedHub.leaveGroup(this.correlationId);
      this.$feedbackSubmittedHub.$off(
        'feedback-submitted',
        this.onSubmissionComplete
      );
    }
  },

  methods: {
    submitForm() {
      // user can only update the feedback and not submit -> we treat this
      // the same way as the saveForm method
      if (!this.feedbackData.canWrite && this.feedbackData.canUpdate) {
        this.submitting = true;
        this.saveForm();
        return;
      }

      this.correlationId = uuid();
      this.$feedbackSubmittedHub.joinGroup(this.correlationId);
      this.$feedbackSubmittedHub.$on(
        'feedback-submitted',
        this.onSubmissionComplete
      );

      this.submitting = true;
      this.$emit('submit');
    },

    approveForm() {
      this.correlationId = uuid();
      this.$feedbackSubmittedHub.joinGroup(this.correlationId);
      this.$feedbackSubmittedHub.$on(
        'feedback-submitted',
        this.onSubmissionComplete
      );

      this.approving = true;
      this.$emit('approve');
    },

    vetoForm(comment) {
      this.correlationId = uuid();
      this.$feedbackSubmittedHub.joinGroup(this.correlationId);
      this.$feedbackSubmittedHub.$on(
        'feedback-submitted',
        this.onSubmissionComplete
      );

      this.vetoing = true;
      this.$emit('veto', comment);
    },

    onSubmissionComplete() {
      let mode = 'save';
      if (this.submitting) {
        this.submitting = false;
        this.submitComplete = true;
        mode = 'submit';
      }

      if (this.approving) {
        this.approving = false;
        this.approvalComplete = true;
      }

      if (this.vetoing) {
        this.vetoing = false;
        this.vetoComplete = true;
      }

      // user can only update the feedback and not submit -> we treat this
      // the same way as the saveForm method
      if (this.feedbackData.isSubmitted && this.feedbackData.canUpdate) {
        mode = 'save';
      }

      this.$emit('submissionComplete', mode);
    },

    onFeedbackCommentSaved() {
      this.$emit('submissionComplete');
    },

    saveForm() {
      this.saving = true;

      setTimeout(() => {
        this.saving = false;
        this.$emit('submissionComplete');
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep h3 {
  word-break: break-word;
}
@media print {
  .row.flex-row {
    display: block;
  }
  ::v-deep .questionnaire-element-wrapper:first-of-type {
    break-before: auto;
  }
}
</style>
