<template>
  <div>
    <strong v-html="localize(value.title)"></strong>
    <span class="caption" v-html="localize(value.subtitle)"></span>

    <v-simple-table>
      <thead class="secondary">
        <tr>
          <th
            v-for="item in value.items"
            :key="`item_${item.id}`"
            class="white--text"
          >
            {{ localize(item.title) }}
          </th>
          <th style="width: 80px"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-show="value.value.length === 0">
          <td :colspan="value.items.length" class="text-center muted">
            {{ $t('forms.noEntriesAdded') }}
          </td>
        </tr>

        <table-item
          v-for="index in value.value.length"
          v-model="value.value[index - 1]"
          :key="`table_${value.index}_${index}`"
          :items="value.items"
          :readOnly="readOnly"
          @change="updateItem(index - 1)"
          @delete="deleteItem(index - 1)"
        />
      </tbody>

      <tfoot v-if="!readOnly">
        <tr>
          <td :colspan="value.items.length" class="text-center">
            <v-btn small @click="addItem">
              <v-icon>mdi-plus</v-icon>
              {{ $t('targets.addTarget') }}
            </v-btn>
          </td>
        </tr>

        <template v-if="feedbackData && feedbackData.isComparison">
          <tr>
            <td />
          </tr>

          <tr
            v-if="
              getComparisonItems(value.id, feedbackTypes.SELF_EVALUATION)
                .length > 0
            "
          >
            <td :colspan="value.items.length">
              <strong class="muted">
                {{ $t('feedbacks.typeOptions.1') }}
              </strong>
            </td>
          </tr>
          <tr
            v-for="(item, index) in getComparisonItems(
              value.id,
              feedbackTypes.SELF_EVALUATION
            )"
            :key="`${value.id}_${feedbackTypes.SELF_EVALUATION}_${index}`"
          >
            <td v-for="col in value.items" :key="`${col.id}_${getUuid()}`">
              {{ item.columnValues[col.id] }}
            </td>
          </tr>

          <tr
            v-if="
              getComparisonItems(value.id, feedbackTypes.MANAGER_EVALUATION)
                .length > 0
            "
          >
            <td :colspan="value.items.length">
              <strong class="muted">
                {{ $t('feedbacks.typeOptions.2') }}
              </strong>
            </td>
          </tr>
          <tr
            v-for="(item, index) in getComparisonItems(
              value.id,
              feedbackTypes.MANAGER_EVALUATION
            )"
            :key="`${value.id}_${feedbackTypes.MANAGER_EVALUATION}_${index}`"
          >
            <td v-for="col in value.items" :key="`${col.id}_${getUuid()}`">
              {{ item.columnValues[col.id] }}
            </td>
            <td>
              <v-btn
                small
                @click="
                  transferItem(
                    item.columnValues,
                    value.id,
                    feedbackTypes.MANAGER_EVALUATION,
                    item.index
                  )
                "
              >
                <v-icon small>mdi-plus-box-multiple</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </tfoot>
    </v-simple-table>
  </div>
</template>

<script>
import elementMixin from '@/mixins/form-element.js';

import { v4 as uuid } from 'uuid';
import { mapActions, mapState } from 'vuex';
import { feedbackEnums } from '@/enums/feedbacks.js';

import tableItem from './table-element-item.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    feedbackTypes: feedbackEnums.feedbackTypes,
    formFields: {
      columnValues: {}
    }
  }),

  computed: {
    ...mapState('feedbacks', {
      feedbackData: 'currentFeedbackData'
    })
  },

  mounted() {
    if (
      !this.value.value ||
      this.value.value.length === 0 ||
      this.value.value === -1
    ) {
      this.value.value = [];
      setTimeout(() => {
        this.addItem();
      }, 1000);
    }

    if (this.value.tableItems !== null && this.value.tableItems !== undefined) {
      this.value.value = this.value.tableItems.sort((a, b) => {
        if (a.index < b.index) {
          return -1;
        } else if (a.index > b.index) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  },

  methods: {
    ...mapActions({
      saveItem: 'feedbacks/saveTableItem',
      removeItem: 'feedbacks/deleteTableItem'
    }),

    async addItem() {
      let isValid = false;
      for (const value of Object.values(this.formFields)) {
        if (value) {
          isValid = true;
          break;
        }
      }

      if (isValid) {
        this.formFields.id = uuid();

        let newIndex = 1;

        if (this.value.value.length > 0) {
          const itemIndexes = this.value.value.map((x) => x.index);
          const maxIndex = Math.max(...itemIndexes);
          newIndex = maxIndex + 1;
        }

        this.formFields.index = newIndex;

        const copy = JSON.parse(JSON.stringify(this.formFields));
        this.value.value.push(copy);

        const requestData = {
          feedbackId: this.$route.params.id,
          elementId: this.value.id,
          index: this.formFields.index,
          columnValues: this.formFields.columnValues
        };

        await this.saveItem(requestData);

        this.formFields.id = null;
        this.formFields.index = -1;
        this.formFields.columnValues = {};
      }
    },

    deleteItem(index) {
      const data = this.value.value[index];

      const requestData = {
        feedbackId: this.$route.params.id,
        elementId: this.value.id,
        index: data.index
      };

      this.removeItem(requestData);

      this.value.value.splice(index, 1);
    },

    getComparisonItems(elementId, feedbackType) {
      if (!this.feedbackData || !this.feedbackData.isComparison) {
        return [];
      }

      const feedbackTypeItems = this.feedbackData.tableComparisonData.filter(
        (x) => x.feedbackType === feedbackType
      );
      if (feedbackTypeItems.length === 0) {
        return [];
      }

      const elementItems = feedbackTypeItems[0].values.filter(
        (x) => x.elementId === elementId
      );

      if (elementItems.length === 0) {
        return [];
      }

      return elementItems;
    },

    getUuid() {
      return uuid();
    },

    transferItem(values, elementId, feedbackType, itemIndex) {
      const item = {
        id: uuid(),
        index: this.value.value.length + 1,
        columnValues: values
      };

      // add the item to the list of items
      this.value.value.push(item);

      // send the request to save the item
      const requestData = {
        feedbackId: this.$route.params.id,
        elementId: elementId,
        index: item.index,
        columnValues: item.columnValues
      };

      this.saveItem(requestData);

      // and remove the item from the comparison items so it doesn't show up in
      // the list for the self/manager evaluation anymore
      let typeItems = this.feedbackData.tableComparisonData.filter(
        (x) => x.feedbackType === feedbackType
      )[0];

      const itemToBeDeleted = typeItems.values.filter(
        (x) => x.elementId === elementId && x.index === itemIndex
      )[0];

      const arrayIndex = typeItems.values.indexOf(itemToBeDeleted);
      typeItems.values.splice(arrayIndex, 1);
    },

    updateItem(index) {
      const data = this.value.value[index];
      const requestData = {
        feedbackId: this.$route.params.id,
        elementId: this.value.id,
        index: data.index,
        columnValues: data.columnValues
      };

      this.saveItem(requestData);
    }
  },

  mixins: [elementMixin],

  components: {
    tableItem
  }
};
</script>

<style lang="scss" scoped>
.table-element
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
