var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('strong',{domProps:{"innerHTML":_vm._s(_vm.localize(_vm.value.title))}}),_c('span',{staticClass:"caption",domProps:{"innerHTML":_vm._s(_vm.localize(_vm.value.subtitle))}}),_c('v-simple-table',[_c('thead',{staticClass:"secondary"},[_c('tr',[_vm._l((_vm.value.items),function(item){return _c('th',{key:("item_" + (item.id)),staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.localize(item.title))+" ")])}),_c('th',{staticStyle:{"width":"80px"}})],2)]),_c('tbody',[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.value.length === 0),expression:"value.value.length === 0"}]},[_c('td',{staticClass:"text-center muted",attrs:{"colspan":_vm.value.items.length}},[_vm._v(" "+_vm._s(_vm.$t('forms.noEntriesAdded'))+" ")])]),_vm._l((_vm.value.value.length),function(index){return _c('table-item',{key:("table_" + (_vm.value.index) + "_" + index),attrs:{"items":_vm.value.items,"readOnly":_vm.readOnly},on:{"change":function($event){return _vm.updateItem(index - 1)},"delete":function($event){return _vm.deleteItem(index - 1)}},model:{value:(_vm.value.value[index - 1]),callback:function ($$v) {_vm.$set(_vm.value.value, index - 1, $$v)},expression:"value.value[index - 1]"}})})],2),(!_vm.readOnly)?_c('tfoot',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.value.items.length}},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.addItem}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('targets.addTarget'))+" ")],1)],1)]),(_vm.feedbackData && _vm.feedbackData.isComparison)?[_c('tr',[_c('td')]),(
            _vm.getComparisonItems(_vm.value.id, _vm.feedbackTypes.SELF_EVALUATION)
              .length > 0
          )?_c('tr',[_c('td',{attrs:{"colspan":_vm.value.items.length}},[_c('strong',{staticClass:"muted"},[_vm._v(" "+_vm._s(_vm.$t('feedbacks.typeOptions.1'))+" ")])])]):_vm._e(),_vm._l((_vm.getComparisonItems(
            _vm.value.id,
            _vm.feedbackTypes.SELF_EVALUATION
          )),function(item,index){return _c('tr',{key:((_vm.value.id) + "_" + (_vm.feedbackTypes.SELF_EVALUATION) + "_" + index)},_vm._l((_vm.value.items),function(col){return _c('td',{key:((col.id) + "_" + (_vm.getUuid()))},[_vm._v(" "+_vm._s(item.columnValues[col.id])+" ")])}),0)}),(
            _vm.getComparisonItems(_vm.value.id, _vm.feedbackTypes.MANAGER_EVALUATION)
              .length > 0
          )?_c('tr',[_c('td',{attrs:{"colspan":_vm.value.items.length}},[_c('strong',{staticClass:"muted"},[_vm._v(" "+_vm._s(_vm.$t('feedbacks.typeOptions.2'))+" ")])])]):_vm._e(),_vm._l((_vm.getComparisonItems(
            _vm.value.id,
            _vm.feedbackTypes.MANAGER_EVALUATION
          )),function(item,index){return _c('tr',{key:((_vm.value.id) + "_" + (_vm.feedbackTypes.MANAGER_EVALUATION) + "_" + index)},[_vm._l((_vm.value.items),function(col){return _c('td',{key:((col.id) + "_" + (_vm.getUuid()))},[_vm._v(" "+_vm._s(item.columnValues[col.id])+" ")])}),_c('td',[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.transferItem(
                  item.columnValues,
                  _vm.value.id,
                  _vm.feedbackTypes.MANAGER_EVALUATION,
                  item.index
                )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-box-multiple")])],1)],1)],2)})]:_vm._e()],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }