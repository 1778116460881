<template>
  <component
    :is="component"
    v-model="commentText"
    :readOnly="readOnly"
    :printView="printView"
    :value-state="valueState"
    @change="$emit('change')"
  />
</template>

<script>
import multiLine from './multi-line.vue';
import singleLine from './single-line.vue';
import date from './date.vue';

import { formEnums } from '@/enums/forms.js';

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    type: {
      type: Number,
      required: false,
      default: 1
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    },

valueState: {
  type: Number,
  required: false,
  default: -1
}
  },

  data: () => ({
    commentText: ''
  }),

  computed: {
    component() {
      if (this.type === formEnums.textFieldType.MULTI_LINE) {
        return 'multiLine';
      } else if (this.type === formEnums.textFieldType.SINGLE_LINE) {
        return 'singleLine';
      } else if (this.type === formEnums.textFieldType.DATE) {
        return 'date';
      } else {
        return 'multiLine';
      }
    }
  },

  watch: {
    commentText() {
      this.$emit('input', this.commentText);
    },

    value() {
      this.commentText = this.value;
    }
  },

  mounted() {
    this.commentText = this.value;
  },

  components: {
    multiLine,
    singleLine,
    date
  }
};
</script>
