<template>
  <v-text-field
    v-model="commentText"
    :readonly="readOnly || printView"
    hide-details
    filled
    :label="printView ? $t('notAvailable') : $t('comment')"
    :solo="printView"
    :flat="printView"
    @blur="leaveField"
    @focus="enterField"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    commentText: '',
    autoSaveInterval: 30000,
    autoSaveTimer: null
  }),

  watch: {
    commentText() {
      this.$emit('input', this.commentText);
    },

    value() {
      this.commentText = this.value;
    }
  },

  mounted() {
    this.commentText = this.value;
  },

  beforeDestroy() {
    if (this.autoSaveTimer) {
      window.clearTimeout(this.autoSaveTimer);
    }
  },

  methods: {
    enterField() {
      const vm = this;
      vm.autoSaveTimer = window.setInterval(() => {
        vm.$emit('change');
      }, vm.autoSaveInterval);
    },

    leaveField() {
      this.$emit('change');

      if (this.autoSaveTimer) {
        window.clearTimeout(this.autoSaveTimer);
      }
    }
  }
};
</script>
