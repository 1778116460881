<template>
  <div
    ref="wrapper"
    class="questionnaire-element-wrapper"
    :class="{
      halfWidth: isDateField,
      'margin-b-1': elementComponent === 'title-element'
    }"
  >
    <component
      v-if="item !== null"
      v-model="item"
      ref="element"
      :is="elementComponent"
      :readOnly="readOnly"
      :printView="printView"
      :preview="preview"
      :transferredData="transferredData"
      :feedbackId="feedbackId"
      @errorOnSave="$emit('errorOnSave')"
    ></component>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { formEnums } from '@/enums/forms.js';
import { feedbackEnums } from '@/enums/feedbacks.js';

// import groupElement from '@/components/forms/questionnaire/form-elements/group-element.vue';
import titleElement from '@/components/forms/questionnaire/form-elements/title-element.vue';
import textElement from '@/components/forms/questionnaire/form-elements/text-element.vue';
import questionElement from '@/components/forms/questionnaire/form-elements/question-element.vue';
import groupedQuestionElement from '@/components/forms/questionnaire/form-elements/grouped-question-element.vue';
import matrixElement from '@/components/forms/questionnaire/form-elements/question-matrix-element.vue';
import matrixComparison from '@/components/forms/questionnaire/form-elements/question-matrix-comparison.vue';
import tableElement from '@/components/forms/questionnaire/form-elements/table-element.vue';
import targetsElement from '@/components/forms/questionnaire/form-elements/targets/new-targets/component.vue';
import actionsElement from '@/components/forms/questionnaire/form-elements/targets/new-actions/component.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    },

    preview: {
      type: Boolean,
      required: false,
      default: false
    },

    isGroupedElement: {
      type: Boolean,
      required: false,
      default: false
    },

    feedbackId: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      elementType: formEnums.formElements,
      commentFieldType: formEnums.textFieldType,
      feedbackType: feedbackEnums.feedbackTypes,
      processTypes: feedbackEnums.processTypes,
      item: null,
      transferredData: null
    };
  },

  watch: {
    item() {
      this.$emit('input', this.item);
    }
  },

  computed: {
    ...mapState('feedbacks', {
      feedbackData: 'currentFeedbackData'
    }),

    elementComponent() {
      if (this.value.type === this.elementType.GROUP) {
        return 'group-element';
      } else if (this.value.type === this.elementType.TITLE) {
        return 'title-element';
      } else if (this.value.type === this.elementType.TEXT) {
        return 'text-element';
      } else if (this.value.type === this.elementType.INFO_TEXT) {
        return 'info-text-element';
      } else if (
        this.value.type === this.elementType.QUESTION &&
        !this.isGroupedElement
      ) {
        return 'question-element';
      } else if (
        this.value.type === this.elementType.QUESTION &&
        this.isGroupedElement
      ) {
        return 'grouped-question-element';
      } else if (
        this.value.type === this.elementType.MATRIX &&
        !this.isComparison
      ) {
        return 'matrix-element';
      } else if (
        this.value.type === this.elementType.MATRIX &&
        this.isComparison
      ) {
        return 'matrix-comparison';
      } else if (this.value.type === this.elementType.TABLE) {
        return 'table-element';
      } else if (this.value.type === this.elementType.TABLE_REVIEW) {
        return 'table-review-element';
      } else if (this.value.type === this.elementType.TARGETS) {
        return 'targets-element';
      } else if (this.value.type === this.elementType.ACTIONS) {
        return 'actions-element';
      } else if (this.value.type === this.elementType.CUSTOM) {
        if (this.value.customComponent === 'new-targets') {
          return 'targets-element';
        }

        return `${this.$route.params.slug}_${this.value.customComponent}`;
      } else {
        return '';
      }
    },

    isComparison() {
      if (this.feedbackData === null) {
        return false;
      }

      return this.feedbackData.isComparison;
    },

    isDateField() {
      if (
        this.value.type === this.elementType.QUESTION &&
        this.value.commentFieldType === this.commentFieldType.DATE
      ) {
        return true;
      }

      return false;
    },

    transferDataForFeedback() {
      if (
        this.feedbackData.feedbackType === this.feedbackType.MANAGER_EVALUATION
      ) {
        return true;
      }

      if (
        this.feedbackData.processType === this.processTypes.EHG &&
        this.feedbackData.feedbackType === this.feedbackType.DOCUMENTATION
      ) {
        return true;
      }

      return false;
    }
  },

  async mounted() {
    this.item = this.value;

    if (
      this.value &&
      this.value.transferFromElement &&
      this.feedbackData &&
      this.transferDataForFeedback &&
      !this.readOnly &&
      !this.printView
    ) {
      const fieldValue = await this.getFieldValue({
        recipientId: this.feedbackData.recipientId,
        elementData: this.value.transferFromElement
      });

      const data = {
        value: fieldValue.value,
        comment: fieldValue.comment,
        processType: fieldValue.processType,
        careerData: fieldValue.careerData,
        matrixElementData: fieldValue.matrixElementData
      };

      this.transferredData = data;
    }

    this.$refs.wrapper.classList.add(this.elementComponent);
  },

  methods: {
    ...mapActions({
      getFieldValue: 'feedbacks/getFieldValue'
    })
  },

  components: {
    groupElement: () => import('./form-elements/group-element.vue'),
    titleElement,
    textElement,
    questionElement,
    groupedQuestionElement,
    matrixComparison,
    matrixElement,
    tableElement,
    targetsElement,
    actionsElement,

    hays_development: () =>
      import(
        '@/components/forms/questionnaire/form-elements/custom/hays/development/component.vue'
      ),
    hays_ehgCompetencies: () =>
      import(
        '@/components/forms/questionnaire/form-elements/custom/hays/ehg-competencies/component.vue'
      ),
    hays_previousTargets: () =>
      import(
        '@/components/forms/questionnaire/form-elements/custom/hays/previous-targets/component.vue'
      ),

    tableReviewElement: () =>
      import(
        '@/components/forms/questionnaire/form-elements/table-review/table-review-element.vue'
      ),

    infoTextElement: () =>
      import(
        '@/components/forms/questionnaire/form-elements/info-text-element.vue'
      )
  }
};
</script>

<style lang="scss" scoped>
@media print {
  .questionnaire-element-wrapper.title-element,
  .questionnaire-element-wrapper.hays_development {
    break-before: page;
  }

  .questionnaire-element-wrapper.grouped-element-question,
  .questionnaire-element-wrapper.question-element,
  .questionnaire-element-wrapper.question-matrix-element,
  .questionnaire-element-wrapper.table-element-item,
  .questionnaire-element-wrapper.title-element {
    break-inside: avoid;
  }
}
</style>
