<template>
  <div>
    <v-card flat class="segmented" color="secondary">
      <v-card-title> {{ $t('feedbacks.donorComment') }} </v-card-title>
      <v-card-text>
        <v-textarea
          v-model="comment"
          hide-details
          filled
          v-if="!readOnly"
        ></v-textarea>

        <span v-else>{{ comment }}</span>
      </v-card-text>
    </v-card>

    <div class="text-center mt-4">
      <v-tooltip top max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="!readOnly"
            v-on="on"
            color="primary"
            :loading="saving"
            @click="save"
          >
            {{ $t('save') }}
          </v-btn>
        </template>

        <span>{{ $t('feedbacks.donorCommentInfo') }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    comment: '',
    saving: false
  }),

  watch: {
    comment() {
      this.$emit('input', this.comment);
    },

    value() {
      this.comment = this.value;
    }
  },

  mounted() {
    this.comment = this.value;
  },

  methods: {
    ...mapActions({
      saveItemComment: 'feedbacks/saveComment'
    }),

    save() {
      // the save button does nothing. So we just show a loading indicator
      // for a short time and then display a success message
      this.saving = true;
      this.saveItemComment({
        feedbackId: this.$route.params.id,
        elementId: null,
        itemId: null,
        comment: this.comment
      });

      const vm = this;
      setTimeout(() => {
        vm.saving = false;
        vm.$emit('save');
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep
  .theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot:hover {
  background-color: #fff;
}
</style>
