<template>
  <div>
    <h3 class="px-2 px-sm-0">{{ localize(value.title) }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  color: var(--v-secondary-base);
}
</style>
