<template>
  <div>
    <comparison-targets
      v-if="
        (!readOnly || feedbackData.canCompareOnly) &&
        feedbackData &&
        feedbackData.targetComparisonData
      "
      :targets="feedbackData.targetComparisonData"
      :conclusionTargets="items"
      :readOnly="readOnly"
      :comparisonOnly="feedbackData.canCompareOnly"
      class="mb-4"
      @copyTarget="saveTarget"
      @editTarget="editTarget"
    ></comparison-targets>

    <v-card
      v-if="!feedbackData.canCompareOnly"
      flat
      class="segmented targets__card"
    >
      <v-card-title
        v-if="feedbackData && feedbackData.targetComparisonData"
        class="targets__card__title"
      >
        <v-row>
          <v-col cols="12">
            <h5>
              {{ $t('targets.finalTargets') }}
            </h5>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text
        class="pt-0"
        v-if="feedbackData && feedbackData.targetComparisonData && !readOnly"
      >
        <v-row>
          <v-col>
            {{
              $t('targets.finalTargetsDescription', {
                newTarget: $t('targets.newTarget')
              })
            }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="pt-0">
        <actions-table
          v-model="items"
          :readOnly="readOnly"
          :type="targetTableTypes.FEEDBACK_NEW"
          :feedbackType="feedbackType"
          :expanded="printView"
          @deleteTarget="deleteTarget"
          @saveTarget="saveTarget"
        ></actions-table>

        <div v-if="!readOnly" class="mt-4 d-flex justify-end">
          <item-dialog
            v-model="formItem"
            :visible="dialogVisible"
            @save="saveTarget"
            @close="dialogVisible = false"
          ></item-dialog>
          <v-btn color="primary" @click="createTarget">
            <v-icon>mdi-plus</v-icon>
            {{ $tc('targets.newActions', 1) }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import itemDialog from './dialog';
import comparisonTargets from '../previous-targets/component.vue';
import actionsTable from '@/components/feedbacks/targets/actions-table.vue';
import { targetEnums } from '@/enums/targets.js';
import { errorCodes } from '@/enums/errorCodes.js';
import { formEnums } from '@/enums/forms.js';

import { mapActions, mapState } from 'vuex';

export default {
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    value: {
      type: Object,
      required: true
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    },

    feedbackId: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      dialogVisible: false,
      formItem: null,
      targetTableTypes: targetEnums.tableTypes,
      items: [],
      validationMode: formEnums.validationMode,
      validationResult: formEnums.validationResult
    };
  },

  computed: {
    ...mapState('feedbacks', {
      feedbackData: 'currentFeedbackData'
    }),

    feedbackType() {
      return this.feedbackData?.feedbackType ?? -1;
    }
  },

  mounted() {
    if (
      this.feedbackData &&
      this.feedbackData.targetItems &&
      this.feedbackData.targetItems.length > 0
    ) {
      this.items = this.feedbackData.targetItems;
    }
  },

  methods: {
    ...mapActions({
      saveTargetItem: 'feedbacks/saveTarget',
      deleteTargetItem: 'feedbacks/deleteTarget'
    }),

    async saveTarget(targetItem) {
      targetItem.feedbackId = this.feedbackId;
      if (!this.feedbackId || this.feedbackId === '') {
        targetItem.feedbackId = this.$route.params.id;
      }

      if (targetItem.index && targetItem.index > 0) {
        const itemIndex = this.items.findIndex(
          (x) => x.index === targetItem.index
        );

        if (itemIndex >= 0) {
          this.items[itemIndex] = targetItem;
        }
      } else {
        targetItem.index = this.items.length + 1;
        this.items.push(targetItem);
      }

      targetItem.targetType = targetEnums.targetType.ACTIONS;

      const result = await this.saveTargetItem(targetItem);
      if (
        result.errorCode &&
        result.errorCode === errorCodes.errorCodes.UNEXPECTED_EXCEPTION
      ) {
        this.$emit('errorOnSave');
      }
    },

    createTarget() {
      this.formItem = null;
      this.dialogVisible = true;
    },

    editTarget(item) {
      this.formItem = item;
      this.dialogVisible = true;
    },

    async deleteTarget(item) {
      const sortIndex = item.index;
      const itemIndex = this.items.indexOf(item);
      if (itemIndex < 0) {
        return;
      }

      this.items.splice(itemIndex, 1);

      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].index >= sortIndex) {
          this.items[i].index--;
          this.saveTarget(this.items[i]);
        }
      }

      const result = await this.deleteTargetItem(item.id);
      if (
        result.errorCode &&
        result.errorCode === errorCodes.errorCodes.UNEXPECTED_EXCEPTION
      ) {
        this.$emit('errorOnSave');
      }
    },

    validate() {
      let elementValidationResult = this.validationResult.VALID;

      if (
        this.items.length === 0 &&
        this.value.elementValidationMode !== this.validationMode.OPTIONAL
      ) {
        elementValidationResult = this.validationResult.INVALID;
      }

      return {
        id: this.value.id,
        elementValidationMode: this.value.elementValidationMode,
        elementValidationResult: elementValidationResult,
        commentValidationMode: this.value.commentValidationMode,
        commentValidationResult: this.validationResult.VALID,
        elementTitle: this.localize(this.value.title),
        elementIndex: this.value.index
      };
    }
  },

  components: {
    itemDialog,
    comparisonTargets,
    actionsTable
  }
};
</script>

<style lang="scss" scoped>
.segmented.targets__card .v-card__text {
  border-radius: 5px;
}
</style>
