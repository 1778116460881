<template>
  <div class="table">
    <h1>{{ $t(`targets.title.${type}`) }}</h1>
    <div class="table-header">
      <v-row class="d-none d-md-flex">
        <v-col cols="5" class="caption">
          {{ $tc('targets.actions', 1) }}
        </v-col>

        <template>
          <v-col cols="3" class="caption"> {{ $t('targets.date') }} </v-col>

          <v-col cols="2" class="caption">
            <template v-if="statusIncluded">
              {{ $t('feedbacks.status') }}
            </template>
          </v-col>
          <v-col cols="2" class="caption"> </v-col>
        </template>
      </v-row>
    </div>

    <div
      v-if="!loading && value && value.length > 0"
      class="overview-body table-body"
    >
      <template v-for="targetItem in sorted">
        <action-item
          :value="targetItem"
          :key="`target_table_item_${targetItem.id}`"
          :readOnly="readOnly"
          :canCopy="canCopy"
          :canDelete="canDelete"
          :canComment="canComment"
          :canChangeStatus="canChangeStatus"
          :commentsDisabled="commentsDisabled"
          :statusIncluded="statusIncluded"
          :isArchived="isArchived"
          :expanded="expanded"
          @deleteTarget="deleteTarget"
          @saveTarget="saveTarget($event)"
          @copyTarget="copyTarget"
        ></action-item>
      </template>
    </div>
    <div v-else-if="loading" class="mt-4">
      <v-row>
        <v-col cols="12">
          <v-progress-linear indeterminate></v-progress-linear>
        </v-col>
      </v-row>
    </div>
    <div v-else class="mt-4">
      <v-row>
        <v-col cols="12">
          <v-alert text type="info">
            {{ emptySectionText }}
          </v-alert>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import actionItem from '@/components/feedbacks/targets/action-table-item';
import { targetEnums } from '@/enums/targets.js';
import { feedbackEnums } from '@/enums/feedbacks.js';

export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: () => true
    },

    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },

    copiedTargets: {
      type: Array,
      required: false,
      default: () => []
    },

    type: {
      type: Number,
      required: true
    },

    feedbackType: {
      type: Number,
      required: false,
      default: -1
    },

    expanded: {
      type: Boolean,
      required: false,
      default: false
    },

    statusReadOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      targetTableTypes: targetEnums.tableTypes,
      statusIncluded: true,
      commentsDisabled: true,
      canDelete: false,
      canCopy: false,
      canComment: false,
      canChangeStatus: false,
      isArchived: false
    };
  },

  mounted() {
    switch (this.type) {
      case this.targetTableTypes.FEEDBACK_OLD:
        this.canDelete = !this.readOnly;
        this.canCopy = true;
        this.statusIncluded = false;
        break;
      case this.targetTableTypes.FEEDBACK_NEW:
        this.canDelete = !this.readOnly;
        this.statusIncluded = false;
        break;
      case this.targetTableTypes.COPIED:
      case this.targetTableTypes.NEW:
        this.canChangeStatus = !this.statusReadOnly;
        this.canComment = true;
        this.commentsDisabled = false;
        break;
      case this.targetTableTypes.ARCHIVED:
        this.canChangeStatus = !this.statusReadOnly;
        this.isArchived = true;
        this.commentsDisabled = false;
        break;
      case this.targetTableTypes.REVIEW:
        this.canChangeStatus = !this.statusReadOnly;
        break;
      default:
        break;
    }
  },

  computed: {
    sorted() {
      const targets = this.value;
      targets.sort((x, y) => (new Date(x.date) < new Date(y.date) ? -1 : 1));
      for (let i = 0; i < targets.length; i++) {
        targets[i].index = i + 1;
      }

      return targets;
    },

    emptySectionText() {
      if (this.type === this.targetTableTypes.COPIED) {
        return this.$t(`targets.noCopiedActions`);
      }

      if (
        this.type === this.targetTableTypes.FEEDBACK_NEW &&
        !this.readOnly &&
        this.feedbackType !== feedbackEnums.feedbackTypes.DOCUMENTATION
      ) {
        return this.$t(`targets.noActionsHint`);
      }

      return this.$t(`targets.noActionsAvailable`);
    }
  },

  methods: {
    copyTarget(item) {
      this.$emit('copyTarget', item);
    },

    deleteTarget(item) {
      this.$emit('deleteTarget', item);
    },

    saveTarget(target) {
      this.$emit('saveTarget', target);
    }
  },

  components: {
    actionItem
  }
};
</script>

<style scoped lang="scss">
.table {
  margin-left: -3px;
  margin-right: -3px;
  margin-bottom: 2em;

  .overview-body.table-body {
    background: none;
  }
}

.table strong {
  color: #000;
}
</style>
