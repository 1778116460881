<template>
  <tr>
    <td
      v-for="columnItem in items"
      :key="`answer_item_${columnItem.id}`"
      class="white--text"
    >
      <v-textarea
        v-model="value.columnValues[columnItem.id]"
        rows="1"
        auto-grow
        filled
        class="my-4"
        hide-details
        :placeholder="$t('forms.enterTargetDefinition')"
        :readonly="readOnly"
        @change="itemChanged(columnItem.id)"
        @blur="fieldBlurred(columnItem.id)"
      ></v-textarea>
    </td>
    <td>
      <v-btn small :disabled="readOnly" @click="$emit('delete')">
        <v-icon small>mdi-delete-forever</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },

    value: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    changedItems: {}
  }),

  methods: {
    itemChanged(itemId) {
      this.changedItems[itemId] = true;
    },

    fieldBlurred(itemId) {
      if (this.changedItems[itemId]) {
        this.$emit('change');
        this.changedItems[itemId] = false;
      }
    }
  }
};
</script>
